import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { PrincipalService } from "./principal.service";
import { ROLES } from "./roles.constants";

@Injectable({
  providedIn: "root",
})
export class AuthorizedRoutes {
  constructor(
    private readonly router: Router,
    private readonly principalService: PrincipalService
  ) {}

  navigate(): void {
    const role = this.principalService.getUserRole();
    if (role === ROLES.ROLE_KP_PARTNER) {
      this.router.navigateByUrl("dashboard/home");
    } else {
      this.router.navigateByUrl("kcredit/overview");
    }
        
    }
  }

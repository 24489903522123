import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { KREDILINE_SERVER_URL } from "src/app/app.constants";
import { GET_BRE_DATA, POST_DEVIATION_REMARKS } from "src/app/shared/constants/Api.constants";

@Injectable({
  providedIn: "root",
})
export class BusinessRuleEngineService {
  public breResponse: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private readonly http: HttpClient) {}

  fetchBreCondition(
    partnerCustomerId = null,
    partnerApplicationId = null
  ): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params.append("partnerCustomerId", partnerCustomerId);
    params = params.append("partnerApplicationId", partnerApplicationId);
    return this.http.get(`${KREDILINE_SERVER_URL}${GET_BRE_DATA}`, { params });
  }

  getBreResponse(): Observable<any> {
    return this.breResponse.asObservable();
  }
  setBreResponse(data: any): void {
    this.breResponse.next(data);
  }

  postDeviationRemarks(payload: any): Observable<any> {
    return this.http.put(POST_DEVIATION_REMARKS, payload);
  }
}

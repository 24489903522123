import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AmlService } from '../../services/anti-money-laundering.service';
import { getProperty } from 'src/app/utils/app.utils';

const match = "assets/images/aml/match.svg";
const noMatch = "assets/images/aml/no-match.svg";

@Component({
  selector: "app-aml-details-accordion",
  templateUrl: "./aml-details-accordion.component.html",
  styleUrls: ["./aml-details-accordion.component.scss"],
})
export class AmlDetailsAccordionComponent implements OnInit {
  @Input() AMLDetail: any = {};
  @Input() loanApplicationId: any = "";
  @Input() branchCode: any = "";
  @Output() refreshAmlData: EventEmitter<any> = new EventEmitter<any>();

  nameLabel: string = "";
  hasMatch: boolean = false;

  constructor(
    private readonly sanitizer: DomSanitizer,
    private readonly amlService: AmlService
  ) {}

  ngOnInit(): void {
    this.nameLabel =
      getProperty(this.AMLDetail, "type", "Applicant").toLowerCase() ===
      "applicant"
        ? "Applicant Name"
        : "Co-Applicant Name";
    this.hasMatch =
      getProperty(this.AMLDetail, "matchStatus", "").toLowerCase() !==
      "no match";
  }

  fetchDisplayIcon(status: string = "") {
    let icon = match;
    if (status.toLowerCase() === "no match") {
      icon = noMatch;
    }
    return this.sanitizer.bypassSecurityTrustResourceUrl(icon);
  }

  initiateAml(): void {
    const payload: any = {};
    payload.name = getProperty(this.AMLDetail, "name", "");
    payload.yearOfBirth = getProperty(this.AMLDetail, "yearOfBirth", "");
    payload.type = getProperty(this.AMLDetail, "type", "");
    payload.loanApplicationId = this.loanApplicationId;
    this.amlService.initiateAML(payload, this.branchCode);
  }

  showRefreshButton(): boolean {
    const status: string = getProperty(this.AMLDetail, "status", "failed");
    return status.toLowerCase() === "in progress";
  }

  refreshAml(): void {
    this.refreshAmlData.emit();
  }

}

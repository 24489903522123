export class KCreditLoanApplication {
  constructor(
    public id?: number,
    public assignee?: string,
    public version?: number,
    public loanTenure?: number,
    public customerNumber?: any,
    public applicationNumber?: any,
    public applicationDate?: string,
    public accountNumber?: any,
    public purpose?: string,
    public loanAmount?: number,
    public loanAmountSanctioned?: number,
    public loanAmountDisbursed?: number,
    public disbursementDate?: any,
    public status?: number,
    public statusUpdatedDate?: any,
    public statusUpdatedBy?: string,
    public productCode?: string,
    public sanctionedDate?: any,
    public sanctionedBy?: string,
    public sanctionRemarks?: string,
    public lienMarkedDate?: any,
    public lienMarkedBy?: string,
    public openedDate?: any,
    public openedBy?: string,
    public signedLoanApplicationFormId?: number,
    public signedLienFormId?: number,
    public primaryPaymentMode?: string,
    public umrn?: number,
    public disbursedBankAccountReferenceNumber?: any,
    public kycConsent?: any,
    public kycConsentDate?: any,
    public lienConsent?: any,
    public lienConsentDate?: any,
    public customerSubscriptionNumber?: string,
    public partnerSchemeId?: number,
    public partnerLoginId?: number,
    public partnerLoanId?: any,
    public partnerExtraInfo?: string,
    public isEdited?: boolean,
    public isSelected?: boolean,
    public customerName?: string,
    public loanApplicationStatusKey?: string,
    public lendingPartnerCode?: any,
    public loanType?: any,
    public firstRepaymentDate?: any,
    public loanUpdateState?: any,
    public secondaryMobileNumber?: any,
    public techIssue?: any,
    public normalInterestRate?: any,
    public dueDay?: any,
    public signatureId?: any,
    public nachMandateStatus?: any,
    public physicalLienStatus?: any,
    public physicalLienStatusKey?: any,
    public loanClosedDate?: any,
    public emiStartDate?: any,
    public partnerEmiStartDate?: any,
    public nextEmiDate?: any,
    public monthlyEmi?: any,
    public pendingEmi?: any,
    public paymentStatus?: any,
    public paymentId?: any,
    public paymentType?: any,
    public advancePaid?: any,
    public overdueAmount?: any,
    public closestDueDate?: any,
    public numDemands?: any,
    public oldestDueDemandNum?: any,
    public nachMandateConsumerId?: any,
    public outstandingLoanAmount?: any,
    public solutionCode?: any,
    public eligibilityPercent?: any,
    public channelPartnerCode?: any,
    public agreementConsent?: any,
    public acceptanceDate?: any,
    public documentId?: any,
    public amountPaid?: any,
    public balance?: any,
    public emiDate?: Date,
    public nextDueDate?: any,
    public totalRepaid?: any,
    public outstandingBalance?: any,
    public feeDue?: any,
    public penalInterestDue?: any,
    public eligibilityResult?: any,
    public kiscore?: any,
    public applicationStatus?: any,
    public firstTimeLoan?: boolean,
    public subsectorCode?: any,
    public emiAmount?: any,
    public eligibility?: any,
    public additionalData?: any,
    public approvePhase?: any,
    public rejectPhase?: any,
    public jlgSizeMinimum?: any,
    public minJlgSize?: any,
    public currentGroupSize?: any,
    public groupId?: any,
    public ucic?: any,
    public associateLenderId?: any,
    public loanTenureUnit?: string,
    public isFirstTimeLoan?: any,
    public netLoanAmountDisbursed?: any,
    public disbursementStatus?: any,
    public kycGroupId?: any,
    public bankDetailId?: any,
    public aadhaarVerificationStatus?: any,
    public panVerificationStatus?: any,
    public adrProcessingStage?: any,
    public reviewNotification?: any,
    public partnerCustomerId?: string,
    public lenderCustomerId?: string
  ) {}
}

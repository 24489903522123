import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { NotificationsService } from "../../../services/notifications.service";
import { get } from "lodash";

@Component({
  selector: "app-notifications",
  templateUrl: "./notification.html",
  styleUrls: ["./notification.css"],
})
export class NotificationComponent implements OnInit, OnChanges {
  @Input() remarks: string = "";
  @Input() actionRequired: string = "";
  @Input() applicationStatus: string = "";
  @Input() reviewDateTime: string = "";
  @Input() lender: string = "";
  @Input() workflow: string = "";
  @Input() isReferred: boolean = false;
  
  errors: any = {};
  notification: string = "";
  canShowReview: boolean = false;

  notificationCount = 0;

  notificationsList: string[] = [];

  constructor(private readonly notificationService: NotificationsService) {}

  ngOnInit(): void {
    this.notificationService.notifications$.subscribe((response) => {
      this.notificationsList = [];
      this.notification = response.notificationsList;
      if (this.notification) {
        this.notificationsList = this.notification.split(",");
      }
      this.notificationCount = this.notificationsList.length;
      this.checkRemarks();
      this.errors = response.errors;
      this.notificationCount += Object.keys(this.errors).length; 
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!get(changes, "remarks.firstChange", true)){
      this.checkRemarks();
    }
  }

  checkRemarks(): void {
    this.canShowReview =
        [
          "reject",
          "retry",
          "pending",
          "cancelled",
          "externalpending",
          "approve",
          "agreementretry",
          "pendingagreement",
          "agreementreceived",
        ].includes(this.applicationStatus) && !!this.remarks;

      if (this.canShowReview) {
        this.notificationCount++;
      }
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UI_COMPONENTS } from "src/app/constants/ui-config";
import { getProperty } from "src/app/utils/app.utils";
import { KcreditLoanDetailsModel } from "../../../loan/kcredit-loanDetails.model";
import { UiConfigService } from "../../../services/ui-config.service";

@Component({
  selector: "app-eligibility-norms",
  templateUrl: "./eligibility-norms.component.html",
  styleUrls: ['../../review-section-fields.scss']
})
export class EligibilityNormsComponent implements OnInit {
  @Input() loanDetails: KcreditLoanDetailsModel;
  @Input() uiFields: any = {};
  @Input() loanId: number = null;
  @Input() editSections: boolean = false;
  @Input() enableEdit: boolean = false;
  @Output() saveData: EventEmitter<any> = new EventEmitter<any>(); 


  constructor(private readonly uiConfigService: UiConfigService) {}

  uiFieldsMap = [];

  ngOnInit(): void {
    this.uiConfigService.getUiConfig(UI_COMPONENTS.LOAN_REVIEW).subscribe(
      (response) => {
        const sectionConfig = this.uiConfigService.getUiConfigurationsBySection(
          response,
          "BRE_WITH_ELIGIBILITY_NORMS",
          true
        );

        this.uiFieldsMap = getProperty(sectionConfig, "uiFieldsMap", []);
      },
      (error) => console.error(error)
    );
  }
}
